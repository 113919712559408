import { useNavigate, useLocation } from "react-router-dom";
import AccountPreview from "../../components/AccountPreview";
import svg from "../../utils/svg";
import Table from "../../components/table/Table";
import { formatDate } from "../../utils/func";

function PreOrdersList() {
  const navigate = useNavigate();
  const {
    state: { data },
  } = useLocation();
  const head = [
    {
      title: "#",
      target: "#",
      className: "count",
    },
    {
      title: "User",
      target: ["profileImage", "firstName", "lastName"],
      render: (value) => (
        <AccountPreview
          user={{
            profileImage: value[0],
            firstName: value[1],
            lastName: value[2],
          }}
        />
      ),
    },
    {
      title: "Email",
      target: "email",
      render: (v) => <span style={{ textAlign: "left" }}>{v}</span>,
    },
    {
      title: "Status",
      target: "status",
      render: (value) => <span className={`status ${value}`}>{value}</span>,
    },
    {
      title: "Phone Number",
      target: "phoneNumber",
      render: (v) => v,
    },

    {
      title: "Management",
      target: "management",
      render: (v) => v,
    },
    {
      title: "Country",
      target: "country",
      render: (v) => v,
    },
    {
      title: "Is Paid",
      target: "isPaid",
      render: (v) => (
        <span className={`status ${v?.toString()}`}>
          {v?.toString()?.toUpperCase()}
        </span>
      ),
    },
    {
      title: "Date",
      target: "date",
      render: formatDate,
    },
    // {
    //   title: "Actions",
    //   target: "_id",
    //   render: (id) => (
    //     <button
    //       className="action flex align-center"
    //       onClick={() => {
    //         // navigate(links.businesses.view(id), {
    //         //   state: { data: preOrders.filter((a) => a._id == id)[0] },
    //         // });
    //       }}
    //     >
    //       {svg.eye()} <span className="f700">VIEW</span>
    //     </button>
    //   ),
    // },
  ];

  return (
    <div>
      <Table
        data={data.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )}
        head={head}
        title="Pre-Orders"
      />
    </div>
  );
}

export default PreOrdersList;
